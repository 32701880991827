import gql from 'graphql-tag';
import { auth } from 'config/client';
import {
  GET_STARTED_REQUEST,
  GET_STARTED_SUCCESS,
  GET_STARTED_FAILURE,
  CREATE_SHOP_USER_REQUEST,
  CREATE_SHOP_USER_SUCCESS,
  CREATE_SHOP_USER_FAILURE,
  CONFIRM_SHOP_USER_REQUEST,
  CONFIRM_SHOP_USER_SUCCESS,
  CONFIRM_SHOP_USER_FAILURE,
  CREATE_SHOP_REQUEST,
  CREATE_SHOP_SUCCESS,
  CREATE_SHOP_FAILURE,
  CREATE_INVITE_REQUEST,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_AUTH_ERRORS,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  FIND_SHOP_REQUEST,
  FIND_SHOP_SUCCESS,
  FIND_SHOP_FAILURE,
  UPDATE_EMAIL_SETTINGS_REQUEST,
  UPDATE_EMAIL_SETTINGS_SUCCESS,
  UPDATE_EMAIL_SETTINGS_FAILURE,
  CHECK_EMAIL_HASH_REQUEST,
  CHECK_EMAIL_HASH_SUCCESS,
  CHECK_EMAIL_HASH_FAILURE,
  CHECK_FIND_TOKEN_REQUEST,
  CHECK_FIND_TOKEN_SUCCESS,
  CHECK_FIND_TOKEN_FAILURE,
  CHECK_SHOP_NAME_REQUEST,
  CHECK_SHOP_NAME_SUCCESS,
  CHECK_SHOP_NAME_FAILURE,
} from 'constants/auth';
import {
  UPDATE_SHOP_SUCCESS,
  UPDATE_META_REQUEST,
  UPDATE_META_SUCCESS,
  UPDATE_META_FAILURE,
} from 'constants/shop';

const maxAge = auth.jwt.expires;

/**
 * Get Started actions
 */
function getStartedRequest(email) {
  return {
    type: GET_STARTED_REQUEST,
    payload: {
      email,
    },
  };
}

function getStartedSuccess() {
  return {
    type: GET_STARTED_SUCCESS,
  };
}

function getStartedFailure(errors) {
  return {
    type: GET_STARTED_FAILURE,
    payload: {
      errors,
    },
  };
}

export function getStarted(email) {
  return async (dispatch, getState, { client, history }) => {
    dispatch(getStartedRequest(email));
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation getStarted($email: String!) {
            getStarted(email: $email) {
              errors {
                message
              }
            }
          }
        `,
        variables: { email },
      });

      const { errors } = data.getStarted;

      if (errors.length > 0) {
        dispatch(getStartedFailure(errors));
      } else {
        dispatch(getStartedSuccess());
        history.push('/get-started');
      }
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(getStartedFailure(errors));
    }
  };
}

/**
 * Create User actions
 */
function createUserRequest(email) {
  return {
    type: CREATE_SHOP_USER_REQUEST,
    payload: {
      email,
    },
  };
}

function createUserSuccess(user, token) {
  return {
    type: CREATE_SHOP_USER_SUCCESS,
    payload: {
      user,
      token,
    },
  };
}

function createUserFailure(errors) {
  return {
    type: CREATE_SHOP_USER_FAILURE,
    payload: {
      errors,
    },
  };
}

export function createUser({ email }) {
  return async (dispatch, getState, { client, history }) => {
    dispatch(createUserRequest(email));
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation createUser($email: String!) {
            createUser(email: $email) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                }
                token
              }
              errors {
                message
              }
            }
          }
        `,
        variables: { email },
      });

      const {
        data: { user, token },
        errors,
      } = data.createUser;

      if (errors.length > 0) {
        dispatch(createUserFailure(errors));
      } else {
        dispatch(
          createUserSuccess(
            {
              id: user.id,
              role: user.role,
              verified: user.verified,
              hasPass: user.hasPass,
            },
            token,
          ),
        );

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${token};path=/;max-age=${maxAge};domain=${domain}`;
        }

        history.push('/create/confirm');
      }
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(createUserFailure(errors));
    }
  };
}

/**
 * Confirm Shop User actions
 */
function confirmUserRequest() {
  return {
    type: CONFIRM_SHOP_USER_REQUEST,
  };
}

function confirmUserSuccess(user, token) {
  return {
    type: CONFIRM_SHOP_USER_SUCCESS,
    payload: {
      user,
      token,
    },
  };
}

function confirmUserFailure(errors) {
  return {
    type: CONFIRM_SHOP_USER_FAILURE,
    payload: {
      errors,
    },
  };
}

export function confirmUser(verifyCode) {
  return async (dispatch, getState, { client, history }) => {
    const {
      user: { id: userId },
    } = getState().auth;

    dispatch(confirmUserRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation confirmUser($userId: String!, $verifyCode: String!) {
            confirmUser(userId: $userId, verifyCode: $verifyCode) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                }
                token
              }
              errors {
                type
                message
              }
            }
          }
        `,
        variables: { userId, verifyCode },
      });

      const {
        data: { user, token },
        errors,
      } = data.confirmUser;

      if (errors.length > 0) {
        dispatch(confirmUserFailure(errors));
      } else {
        dispatch(
          confirmUserSuccess(
            {
              id: user.id,
              role: user.role,
              verified: user.verified,
              hasPass: user.hasPass,
            },
            token,
          ),
        );

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${token};path=/;max-age=${maxAge};domain=${domain}`;
        }

        history.push('/create/new');
      }

      return { errors };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(confirmUserFailure(errors));

      return {};
    }
  };
}

/**
 * Create Shop actions
 */
function createShopRequest() {
  return {
    type: CREATE_SHOP_REQUEST,
  };
}

function createShopSuccess({ user, token }) {
  return {
    type: CREATE_SHOP_SUCCESS,
    payload: {
      user,
      token,
    },
  };
}

function createShopFailure(errors) {
  return {
    type: CREATE_SHOP_FAILURE,
    payload: {
      errors,
    },
  };
}

export function createShop(name) {
  return async (dispatch, getState, { client, history }) => {
    const {
      user: { id: userId },
    } = getState().auth;

    dispatch(createShopRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation createShop($userId: String!, $name: String!) {
            createShop(userId: $userId, name: $name) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                  fullName
                  shopId
                  shop {
                    id
                    url
                    name
                    subdomain
                  }
                }
                token
              }
              errors {
                type
                message
              }
            }
          }
        `,
        variables: { userId, name },
      });

      const {
        data: { user, token },
        errors,
      } = data.createShop;

      if (errors.length > 0) {
        dispatch(createShopFailure(errors));
      } else {
        dispatch(createShopSuccess({ user, token }));

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${token};path=/;max-age=${maxAge};domain=${domain}`;
        }

        history.push('/create/invite');
      }

      return { errors };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(createShopFailure(errors));

      return {};
    }
  };
}

/**
 * Create Invites actions
 */
function createInvitesRequest() {
  return {
    type: CREATE_INVITE_REQUEST,
  };
}

function createInvitesSuccess() {
  return {
    type: CREATE_INVITE_SUCCESS,
  };
}

function createInvitesFailure(errors) {
  return {
    type: CREATE_INVITE_FAILURE,
    payload: {
      errors,
    },
  };
}

export function createInvites({ emails, redirect }) {
  return async (dispatch, getState, { client, history }) => {
    let { id: shopId } = getState().shop;
    const { user } = getState().auth;

    // get shopId from user for /create/invite page
    if (!shopId) shopId = user.shopId;

    dispatch(createInvitesRequest());

    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation createInvites($shopId: String!, $emails: [String]!) {
            createInvites(shopId: $shopId, emails: $emails) {
              errors {
                message
              }
            }
          }
        `,
        variables: { shopId, emails },
      });

      const { errors } = data.createInvites;

      if (errors.length > 0) {
        dispatch(createInvitesFailure(errors));
      } else {
        dispatch(createInvitesSuccess());
        if (redirect) history.push(redirect);
      }

      return { errors };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(createInvitesFailure(errors));

      return {};
    }
  };
}

/**
 * Update User actions
 */
function updateUserRequest() {
  return {
    type: UPDATE_USER_REQUEST,
  };
}

function updateUserSuccess({ user, token }) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: {
      user,
      token,
    },
  };
}

function updateUserFailure(errors) {
  return {
    type: UPDATE_USER_FAILURE,
    payload: {
      errors,
    },
  };
}

export function updateUser({ fullName, password, marketingOptin }) {
  return async (dispatch, getState, { client }) => {
    const {
      user: { id: userId },
    } = getState().auth;

    dispatch(updateUserRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation updateUser(
            $userId: String!
            $fullName: String!
            $password: String!
            $marketingOptin: Boolean!
          ) {
            updateUser(
              userId: $userId
              fullName: $fullName
              password: $password
              marketingOptin: $marketingOptin
            ) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                  fullName
                  marketingOptin
                  shopId
                  shop {
                    id
                    url
                    name
                    subdomain
                  }
                }
                token
              }
              errors {
                type
                message
              }
            }
          }
        `,
        variables: { userId, fullName, password, marketingOptin },
      });

      const {
        data: { user, token },
        errors,
      } = data.updateUser;

      if (errors.length > 0) {
        dispatch(updateUserFailure(errors));
      } else {
        dispatch(updateUserSuccess({ user, token }));

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${token};path=/;max-age=${maxAge};domain=${domain}`;
        }
      }

      return { errors };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(updateUserFailure(errors));

      return { errors };
    }
  };
}

/**
 * Update Shop actions
 */
function updateShopSuccess(shop) {
  const { name, subdomain, activated } = shop;
  return {
    type: UPDATE_SHOP_SUCCESS,
    payload: { name, subdomain, activated },
  };
}

export function updateShop({ name, subdomain, activate }) {
  return async (dispatch, getState, { client }) => {
    const {
      user: { id: userId },
    } = getState().auth;
    const { id: shopId } = getState().shop;
    let activated;
    if (activate) activated = true;

    dispatch(updateUserRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation updateShop(
            $shopId: String!
            $userId: String!
            $name: String!
            $subdomain: String!
            $activated: Boolean
          ) {
            updateShop(
              shopId: $shopId
              userId: $userId
              name: $name
              subdomain: $subdomain
              activated: $activated
            ) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                  fullName
                  shopId
                  shop {
                    id
                    url
                    name
                    subdomain
                  }
                }
                shop {
                  id
                  url
                  name
                  subdomain
                  activated
                }
                token
              }
              errors {
                type
                message
              }
            }
          }
        `,
        variables: { shopId, userId, name, subdomain, activated },
      });

      const {
        data: { shop, user, token },
        errors,
      } = data.updateShop;

      if (errors.length > 0) {
        dispatch(updateUserFailure(errors));
      } else {
        if (shop) dispatch(updateShopSuccess(shop));
        if (shop && token) {
          dispatch(updateUserSuccess({ user, token }));
          if (process.env.BROWSER) {
            const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
            document.cookie = `id_token=${token};path=/;max-age=${maxAge};domain=${domain}`;
          }
        }
      }

      return { errors };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(updateUserFailure(errors));

      return { errors };
    }
  };
}

/**
 * Update Shop actions
 */
function updateMetaRequest() {
  return {
    type: UPDATE_META_REQUEST,
  };
}

function updateMetaSuccess(meta) {
  return {
    type: UPDATE_META_SUCCESS,
    payload: { meta },
  };
}

function updateMetaFailure(errors) {
  return {
    type: UPDATE_META_FAILURE,
    payload: {
      errors,
    },
  };
}

export function updateMeta(meta) {
  const {
    addressStreet,
    addressCity,
    addressState,
    addressZip,
    phonePrimary,
    invoiceAgreement,
  } = meta;
  return async (dispatch, getState, { client }) => {
    dispatch(updateMetaRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation updateMeta(
            $addressStreet: String
            $addressCity: String
            $addressState: String
            $addressZip: String
            $phonePrimary: String
            $invoiceAgreement: String
          ) {
            updateMeta(
              addressStreet: $addressStreet
              addressCity: $addressCity
              addressState: $addressState
              addressZip: $addressZip
              phonePrimary: $phonePrimary
              invoiceAgreement: $invoiceAgreement
            ) {
              data {
                shop {
                  meta {
                    addressStreet
                    addressCity
                    addressState
                    addressZip
                    phonePrimary
                    invoiceAgreement
                  }
                }
              }
              errors {
                type
                message
              }
            }
          }
        `,
        variables: {
          addressStreet,
          addressCity,
          addressState,
          addressZip,
          phonePrimary,
          invoiceAgreement,
        },
      });

      const {
        data: { shop },
        errors,
      } = data.updateMeta;

      if (!shop || !shop.meta || errors.length > 0) {
        dispatch(updateMetaFailure(errors));
      } else {
        dispatch(updateMetaSuccess(shop.meta));
      }

      return { errors };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(updateMetaFailure(errors));

      return { errors };
    }
  };
}

/**
 * Sign in actions
 */
export function signInRequest() {
  return {
    type: SIGN_IN_REQUEST,
  };
}

export function signInSuccess({ user, token }) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: {
      user,
      token,
    },
  };
}

export function signInFailure(errors) {
  return {
    type: SIGN_IN_FAILURE,
    payload: {
      errors,
    },
  };
}

export function signIn({ email, password }) {
  return async (dispatch, getState, { client, history }) => {
    const { id: shopId } = getState().shop;

    dispatch(signInRequest());

    try {
      const { data } = await client.query({
        query: gql`
          query signIn($shopId: String!, $email: String!, $password: String!) {
            signIn(shopId: $shopId, email: $email, password: $password) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                  fullName
                  shopId
                  shop {
                    id
                    url
                    name
                    subdomain
                  }
                }
                token
              }
              errors {
                type
                message
              }
            }
          }
        `,
        variables: { shopId, email, password },
      });

      const {
        data: { user, token },
        errors,
      } = data.signIn;

      if (errors.length > 0) {
        dispatch(signInFailure(errors));
      } else {
        dispatch(signInSuccess({ user, token }));

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${token};path=/;max-age=${maxAge};domain=.${domain}`;
        }

        history.push('/');
      }
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(signInFailure(errors));
    }
  };
}

export function signInToken({ token }) {
  return async (dispatch, getState, { client, history }) => {
    const { id: shopId } = getState().shop;

    dispatch(signInRequest());

    try {
      const { data } = await client.query({
        query: gql`
          query signInToken($shopId: String!, $token: String!) {
            signInToken(shopId: $shopId, token: $token) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                  fullName
                  shopId
                  shop {
                    id
                    url
                    name
                    subdomain
                  }
                }
                token
              }
              errors {
                type
                message
              }
            }
          }
        `,
        variables: { shopId, token },
      });

      const {
        data: { user, token: authToken },
        errors,
      } = data.signInToken;

      if (errors.length) {
        dispatch(signInFailure(errors));
      } else {
        dispatch(signInSuccess({ user, token: authToken }));

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${authToken};path=/;max-age=${maxAge};domain=.${domain}`;
        }
      }

      history.push('/');
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(signInFailure(errors));
    }
  };
}

/**
 * SignOut actions
 */
function signOutRequest() {
  return {
    type: SIGN_OUT_REQUEST,
  };
}

function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

export function signOut() {
  return async (dispatch, getState, { history }) => {
    dispatch(signOutRequest());
    const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
    document.cookie = `id_token=;path=/;max-age=-1;domain=.${domain}`;
    dispatch(signOutSuccess());
    history.push('/');
  };
}

/**
 * Clear auth errors actions
 */
function clearAuthErrors() {
  return {
    type: CLEAR_AUTH_ERRORS,
  };
}

export function clearErrors() {
  return async dispatch => {
    dispatch(clearAuthErrors());
  };
}

/**
 * Forgot/reset password actions
 */
function forgotPasswordRequest() {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
}

function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
}

function forgotPasswordFailure(errors) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: {
      errors,
    },
  };
}

export function forgotPassword({ email }) {
  return async (dispatch, getState, { client }) => {
    const { id: shopId } = getState().shop;

    dispatch(forgotPasswordRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation forgotPassword($shopId: String!, $email: String!) {
            forgotPassword(shopId: $shopId, email: $email) {
              errors {
                message
              }
            }
          }
        `,
        variables: { email, shopId },
      });

      const { errors } = data.forgotPassword;

      if (errors.length > 0) {
        dispatch(forgotPasswordFailure(errors));
      } else {
        dispatch(forgotPasswordSuccess());
      }
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(forgotPasswordFailure(errors));
    }
  };
}

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
}

function resetPasswordSuccess({ user, token }) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: {
      user,
      token,
    },
  };
}

function resetPasswordFailure(errors) {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: {
      errors,
    },
  };
}

export function resetPassword({ token, password, passwordConfirm }) {
  return async (dispatch, getState, { client }) => {
    dispatch(resetPasswordRequest());

    if (password !== passwordConfirm) {
      dispatch(
        resetPasswordFailure([
          {
            key: 'password',
            message: 'Confirm New Password does not match New Password.',
          },
        ]),
      );
      return {};
    }

    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation resetPassword($token: String!, $password: String!) {
            resetPassword(token: $token, password: $password) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                  fullName
                  shopId
                  shop {
                    id
                    url
                    name
                    subdomain
                  }
                }
                token
              }
              errors {
                message
              }
              tokenValid
            }
          }
        `,
        variables: { token, password },
      });

      const {
        data: { user, token: authToken },
        errors,
        tokenValid,
      } = data.resetPassword;

      if (errors.length > 0) {
        dispatch(resetPasswordFailure(errors));
      } else {
        dispatch(
          resetPasswordSuccess({
            user,
            token: authToken,
          }),
        );

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${authToken};path=/;max-age=${maxAge};domain=.${domain}`;
        }
      }

      return { errors, tokenValid };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(resetPasswordFailure(errors));
      return {};
    }
  };
}

/**
 * Accept Invite to Create User Account
 */

function acceptInviteRequest() {
  return {
    type: ACCEPT_INVITE_REQUEST,
  };
}

function acceptInviteSuccess({ user, token }) {
  return {
    type: ACCEPT_INVITE_SUCCESS,
    payload: {
      user,
      token,
    },
  };
}

function acceptInviteFailure(errors) {
  return {
    type: ACCEPT_INVITE_FAILURE,
    payload: {
      errors,
    },
  };
}

export function acceptInvite({ token, fullName, password }) {
  return async (dispatch, getState, { client, history }) => {
    dispatch(acceptInviteRequest());

    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation acceptInvite(
            $token: String!
            $fullName: String
            $password: String!
          ) {
            acceptInvite(
              token: $token
              fullName: $fullName
              password: $password
            ) {
              data {
                user {
                  id
                  role
                  email
                  status
                  verified
                  hasPass
                  isAdmin
                  fullName
                  shopId
                  shop {
                    id
                    url
                    name
                    subdomain
                  }
                }
                token
              }
              errors {
                message
              }
              tokenValid
            }
          }
        `,
        variables: { token, fullName, password },
      });

      const {
        data: { user, token: authToken },
        errors,
        tokenValid,
      } = data.acceptInvite;

      if (errors.length > 0) {
        dispatch(acceptInviteFailure(errors));
      } else {
        dispatch(
          acceptInviteSuccess({
            user,
            token: authToken,
          }),
        );
        history.push('/');

        if (process.env.BROWSER) {
          const domain = window.App.apiUrl.replace(/(^\w+:|^)\/\//, '');
          document.cookie = `id_token=${authToken};path=/;max-age=${maxAge};domain=.${domain}`;
        }
      }

      return { errors, tokenValid };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(acceptInviteFailure(errors));
      return {};
    }
  };
}

/**
 * Get Started actions
 */
function findShopRequest() {
  return {
    type: FIND_SHOP_REQUEST,
  };
}

function findShopSuccess() {
  return {
    type: FIND_SHOP_SUCCESS,
  };
}

function findShopFailure(errors) {
  return {
    type: FIND_SHOP_FAILURE,
    payload: {
      errors,
    },
  };
}

export function findShop({ email }) {
  return async (dispatch, getState, { client }) => {
    dispatch(findShopRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation findShop($email: String!) {
            findShop(email: $email) {
              errors {
                message
              }
            }
          }
        `,
        variables: { email },
      });

      const { errors } = data.findShop;

      if (errors.length > 0) {
        dispatch(findShopFailure(errors));
      } else {
        dispatch(findShopSuccess());
      }
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(findShopFailure(errors));
    }
  };
}

/**
 * Update User actions
 */
function updateEmailSettingsRequest() {
  return {
    type: UPDATE_EMAIL_SETTINGS_REQUEST,
  };
}

function updateEmailSettingsSuccess() {
  return {
    type: UPDATE_EMAIL_SETTINGS_SUCCESS,
  };
}

function updateEmailSettingsFailure(errors) {
  return {
    type: UPDATE_EMAIL_SETTINGS_FAILURE,
    payload: {
      errors,
    },
  };
}

export function updateEmailSettings({ ehash, marketingOptin }) {
  return async (dispatch, getState, { client }) => {
    dispatch(updateEmailSettingsRequest());
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation updateEmailSettings(
            $ehash: String!
            $marketingOptin: Boolean!
          ) {
            updateEmailSettings(
              ehash: $ehash
              marketingOptin: $marketingOptin
            ) {
              errors {
                type
                message
              }
            }
          }
        `,
        variables: { ehash, marketingOptin },
      });

      const { errors } = data.updateEmailSettings;

      if (errors.length > 0) {
        dispatch(updateEmailSettingsFailure(errors));
      } else {
        dispatch(updateEmailSettingsSuccess());
      }

      return { errors };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(updateEmailSettingsFailure(errors));

      return { errors };
    }
  };
}

/**
 * Check Email Hash actions
 */
function checkEmailHashRequest() {
  return {
    type: CHECK_EMAIL_HASH_REQUEST,
  };
}

function checkEmailHashSuccess() {
  return {
    type: CHECK_EMAIL_HASH_SUCCESS,
  };
}

function checkEmailHashFailure(errors) {
  return {
    type: CHECK_EMAIL_HASH_FAILURE,
    payload: {
      errors,
    },
  };
}

export function checkEmailHash({ ehash }) {
  return async (dispatch, getState, { client }) => {
    dispatch(checkEmailHashRequest());
    try {
      const { data } = await client.query({
        query: gql`
          query emailHash($hash: String!) {
            emailHash(hash: $hash) {
              errors {
                message
              }
              email
              subscribed
            }
          }
        `,
        variables: { hash: ehash },
      });

      const { errors, email, subscribed } = data.emailHash;

      if (errors.length > 0) {
        dispatch(checkEmailHashFailure(errors));
        return { errors };
      }

      dispatch(checkEmailHashSuccess());
      return { errors, email, subscribed };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];
      dispatch(checkEmailHashFailure(errors));

      return { errors };
    }
  };
}

/**
 * Check Find Token actions
 */
function checkFindTokenRequest() {
  return {
    type: CHECK_FIND_TOKEN_REQUEST,
  };
}

function checkFindTokenSuccess(token) {
  return {
    type: CHECK_FIND_TOKEN_SUCCESS,
    payload: {
      token,
    },
  };
}

function checkFindTokenFailure(errors) {
  return {
    type: CHECK_FIND_TOKEN_FAILURE,
    payload: {
      errors,
    },
  };
}

export function checkFindToken({ token }) {
  return async (dispatch, getState, { client }) => {
    dispatch(checkFindTokenRequest());
    try {
      const { data } = await client.query({
        query: gql`
          query checkFindToken($token: String!) {
            checkFindToken(token: $token) {
              errors {
                message
              }
              shops {
                id
                name
                subdomain
                url
              }
            }
          }
        `,
        variables: { token },
      });

      const { errors, shops } = data.checkFindToken;

      if (errors.length) {
        dispatch(checkFindTokenFailure(errors));
        return { errors, tokenValid: false };
      }

      dispatch(checkFindTokenSuccess(token));
      return { errors, shops, tokenValid: true };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];

      dispatch(checkFindTokenFailure(errors));
      return { errors, tokenValid: false };
    }
  };
}

/**
 * Check Shop Name actions
 */
function checkShopNameRequest() {
  return {
    type: CHECK_SHOP_NAME_REQUEST,
  };
}

function checkShopNameSuccess() {
  return {
    type: CHECK_SHOP_NAME_SUCCESS,
  };
}

function checkShopNameFailure(errors) {
  return {
    type: CHECK_SHOP_NAME_FAILURE,
    payload: {
      errors,
    },
  };
}

export function checkShopName({ subdomain }) {
  return async (dispatch, getState, { client }) => {
    dispatch(checkShopNameRequest());
    try {
      const { data } = await client.query({
        query: gql`
          query checkShopName($subdomain: String!) {
            checkShopName(subdomain: $subdomain) {
              errors {
                message
              }
              data {
                shop {
                  id
                  name
                  subdomain
                  url
                }
              }
            }
          }
        `,
        variables: { subdomain },
        fetchPolicy: 'network-only',
      });

      const {
        data: { shop },
        errors,
      } = data.checkShopName;

      if (errors.length) {
        dispatch(checkShopNameFailure(errors));
        return { errors };
      }

      dispatch(checkShopNameSuccess());
      return { errors, shop };
    } catch (e) {
      const errors = [
        {
          key: 'general',
          message: 'Unexpected server error',
        },
      ];

      dispatch(checkShopNameFailure(errors));
      return { errors };
    }
  };
}
