/* eslint-disable max-len */

module.exports = {
  // Authentication
  auth: {
    jwt: {
      expires: 60 * 60 * 24 * 180, // 180 days
    },
  },

  // Stripe
  stripe: {
    public_key:
      process.env.STRIPE_PUBLIC_KEY ||
      'pk_test_VjI4lSHn7Gn9vAAcN5kI2Mrt006OavmBUY',
  },

  // Initial Techsbox Premium Monthly Plans
  plans: [
    {
      name: 'Free',
      price: 0,
      interval: '',
      jobsLimit: 30,
      userLimit: 2,
      features: ['30 jobs / month', '1 user account'],
    },
    {
      id: '821c2362-8db6-46de-943c-295dccd71d2b',
      name: 'Pro',
      price: 29,
      interval: 'month',
      jobsLimit: 90,
      userLimit: 2,
      features: ['90 jobs / month', '2 user accounts'],
    },
    {
      id: '24b9ed11-b209-4944-8753-9aa20c191044',
      name: 'Shop',
      price: 79,
      interval: 'month',
      jobsLimit: null,
      userLimit: 12,
      features: ['Unlimited jobs / month', '12 user accounts'],
      highlight: true,
    },
  ],
};
